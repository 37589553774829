.sectionprocess {
  background-color: $background;
  padding: 110px 0 200px;

  @include responsive-down(tablet) {
    padding: 90px 0 140px;
  }

  @include responsive-down(mobile-large) {
    padding: 65px 0 140px;
  }

  .container {
    @include responsive-down(tablet) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__intro {
    padding: 0 80px 50px;

    @include responsive-down(tablet) {
      padding: 0 10px 50px;
    }

    @include responsive-down(mobile-large) {
      padding: 0 10px 30px;
    }
  }

  &__title {
    @include responsive-down(tablet) {
      margin: 0 0 14px;
    }
  }

  &__grid {
    padding: 90px 0 0;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @include responsive-down(tablet-large) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }

    @include responsive-down(tablet) {
      display: flex;
      flex-direction: column;
      padding: 0;
      grid-row-gap: 40px;
    }

    .itemprocess {
      @include responsive-down(tablet) {
        height: 400px;
      }

      &__title {
        @include responsive-down(tablet) {
          line-height: 1.1;
        }
      }

      &__content {
        @include responsive-down(tablet) {
          padding: 20px 20px 37px;
        }
      }

      &__subtitle {
        @include responsive-down(tablet) {
          font-size: 16px;
          line-height: 1.3;
          margin: 18px 0 10px;

          p {
            font-size: 16px;
            line-height: 1.3;
            margin: 18px 0 10px;
          }
        }
      }

      .link-arrow {
        span {
          @include responsive-down(tablet-extra-large) {
            font-size: 16px;
          }
        }

        .arrow {
          @include responsive-down(tablet-extra-large) {
            width: 16px;
          }
        }
      }

      &--1,
      &:first-child {
        grid-area: 1 / 1 / 2 / 5;

        @include responsive-down(tablet-large) {
          grid-area: 1 / 1 / 3 / 3;
        }
      }

      &--2,
      &:nth-child(2) {
        grid-area: 1 / 5 / 2 / 10;

        @include responsive-down(tablet-large) {
          grid-area: 1 / 3 / 3 / 5;
        }
      }

      &--3,
      &:nth-child(3) {
        grid-area: 2 / 1 / 2 / 4;

        @include responsive-down(tablet-large) {
          grid-area: 3 / 1 / 5 / 3;
        }
      }

      &--4,
      &:nth-child(4) {
        grid-area: 2 / 4 / 2 / 7;

        @include responsive-down(tablet-large) {
          grid-area: 3 / 3 / 5 / 5;
        }
      }

      &--5,
      &:nth-child(5) {
        grid-area: 2 / 7 / 2 / 10;

        @include responsive-down(tablet-large) {
          grid-area: 8 / 1 / 5 / 5;
        }
      }
    }
  }

  &__item {
    &--1,
    &:first-child {
      grid-area: 1 / 1 / 2 / 5;

      @include responsive-down(tablet-large) {
        grid-area: 1 / 1 / 3 / 3;
      }
    }

    &--2,
    &:nth-child(2) {
      grid-area: 1 / 5 / 2 / 8;

      @include responsive-down(tablet-large) {
        grid-area: 1 / 3 / 3 / 5;
      }
    }

    &--3,
    &:nth-child(3) {
      grid-area: 2 / 1 / 3 / 4;

      @include responsive-down(tablet-large) {
        grid-area: 3 / 1 / 5 / 3;
      }
    }

    &--4,
    &:nth-child(4) {
      grid-area: 2 / 4 / 3 / 8;

      @include responsive-down(tablet-large) {
        grid-area: 3 / 3 / 5 / 5;
      }
    }
  }
}
