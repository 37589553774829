.teamcompany {
  background-color: $green-corporate;
  padding: 110px 0 180px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;

  @include responsive-down(tablet) {
    padding: 80px 0 100px;
  }

  .container {
    position: relative;
    z-index: 1;
  }

  &::after {
    content: '';
    width: 140vw;
    height: 140vw;
    aspect-ratio: 1/1;
    background-color: $circle;
    opacity: 0.4;
    position: absolute;
    top: 54%;
    border-radius: 50%;
    z-index: 0;

    @include responsive-down(tablet) {
      width: 240vw;
      height: 240vw;
    }

    @include responsive-down(mobile-large) {
      width: 300vw;
      height: 300vw;
      top: 27%;
    }
  }

  &__intro {
    .title {
      font-size: 68px;
      color: $beige;
      text-align: center;
      font-weight: 400;
      line-height: 1;
      margin: 0 auto 21px;

      @include responsive-down(tablet) {
        font-size: 48px;
        margin: 0 0 30px;
      }

      @include responsive-down(mobile-large) {
        font-size: 38px;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 70px;
    column-gap: 70px;
    padding: 90px 0 0 70px;

    @include responsive-down(tablet-extra-large) {
      column-gap: 40px;
      row-gap: 50px;
      padding: 60px 0 0;
    }

    @include responsive-down(mobile-large) {
      padding: 40px 0 0;
      row-gap: 30px;
    }

    .card-user {
      width: calc(100% / 3 - 50px);
      padding: 0 0 30px;

      @include responsive-down(tablet-extra-large) {
        width: calc(100% / 3 - 30px);
      }

      @include responsive-down(tablet) {
        width: calc(100% / 2 - 20px);
      }

      @include responsive-down(mobile-large) {
        width: 100%;
        padding: 0 0 20px;
      }
    }
  }
}
