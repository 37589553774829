.section-list {
  width: 100%;
  padding: 120px 0;
  background-color: $background;

  .container {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .introtext {
    max-width: 60%;

    @include responsive-down(tablet) {
      max-width: none;
    }
  }

  .list {
    li {
      padding: 0 0 20px 30px;
    }
  }
}

.banner-contact {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px 0;

  .container {
    position: relative;
  }

  &__img {
    width: 100%;
    height: 500px;
    position: relative;

    &::before {
      content: '';
      z-index: 0;
      background: #0a2e22;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      mix-blend-mode: multiply;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 50px;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include responsive-down(tablet) {
      padding: 30px;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
    }

    @include responsive-down(mobile-large) {
      padding: 20px;
    }

    .info {
      max-width: 700px;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $beige;
      font-weight: 400;
      line-height: 1;
      font-size: 3rem;

      @include responsive-down(tablet) {
        font-size: 30px;
      }
    }

    p {
      font-size: 1rem;
      width: 80%;
      text-align: left;
      color: $beige;
      font-weight: 300;

      strong {
        color: $beige;
      }
    }
  }
}
