.ourhistory {
  position: relative;
  padding: 115px 0;

  @include responsive-down(tablet) {
    padding: 90px 0 20px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: rgba(88, 86, 80, 0.5);
    height: 2px;
    top: 280px;
    left: 0;

    @include responsive-down(tablet) {
      top: 250px;
    }

    @include responsive-down(mobile-large) {
      top: 219px;
    }

    @include responsive-down(mobile) {
      top: 237px;
    }

    @include responsive-down(mobile) {
      top: 273px;
    }
  }

  &__intro {
    padding: 0 0 17px;

    @include responsive-down (mobile-large) {
      padding: 0 0 25px;
    }

    @include responsive-down (mobile) {
      max-width: 390px;
    }

    .introtext__title {
      margin: 0 0 15px;

      @include responsive-down (mobile) {
        margin: 0 0 18px;
        max-width: 300px;
      }
    }
  }

  &__introtitle {
    color: $primary;
    font-size: 26px;

    @include responsive-down (mobile-large) {
      font-size: 22px;
    }

    @include responsive-down (mobile-small) {
      max-width: 300px;
    }

    @include responsive-down (mobile-extra-small) {
      font-size: 20px;
    }
  }

  &__list {
    display: flex;
    padding: 56px 0 0;
    overflow-y: hidden;
    overflow-x: visible;
    position: relative;
    scrollbar-width: none;

    @include responsive-down (mobile-large) {
      padding: 16px 0 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    min-width: 280px;
    padding: 0;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    margin: 0 40px 0 0;
    position: relative;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include responsive-down(mobile-extra-large) {
      height: 100%;
      min-width: 250px;
      min-height: 195px;
    }

    @include responsive-down(mobile-large) {
      height: 100%;
      min-width: 250px;
      min-height: 185px;
    }

    &:first-child {
      margin-left: calc(((100vw - 1580px) / 2) + 164px);

      @include responsive-down(tablet-extra-large) {
        margin-left: 30px;
      }

      @include responsive-down(mobile-large) {
        margin-left: 20px;
      }
    }
  }

  &__datetitle {
    color: $primary;
    font-size: 20px;
    line-height: 23px;
    border: 2px solid rgba(88, 86, 80, 0.5);
    border-radius: 50px;
    background-color: $white;
    padding: 5px 10px;
  }

  &__datetext {
    font-size: 16px;
    line-height: 1.3;
    margin: 29px 0 0;
    color: $green-corporate;
    width: 80%;
  }

  &-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #36a9e1;
    background-color: transparent;
    display: inline;
    width: 45px;
    height: 40px;
    padding: 0;

    i {
      font-size: 22px;
      position: absolute;
      right: 12px;
      bottom: 12px;
      color: $grey;
      background-color: transparent;
      border-radius: 100%;
      padding: 3px 1px 3px 3px;

      @include transition(all 0.25s ease-in-out);
    }

    &:hover {
      @include responsive-up(tablet) {
        background-color: transparent;

        i {
          color: $white;
          background-color: $grey;

          @include transition(all 0.25s ease-in-out);
        }
      }
    }

    &:focus {
      background-color: transparent;
    }
  }
}
