.sectionsteps {
  padding: 140px 0;
  background-color: white;

  @include responsive-down(tablet-small) {
    padding: 140px 0 50px;
  }

  .introtext {
    padding: 0 0 50px;
  }

  &__list {
    // overflow-x: auto;
    // overflow-y: hidden;
    // white-space: nowrap;
    position: relative;
    height: 100%;
    display: flex;

    &.tns-slider {
      display: inherit;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .itemstep {
      display: inline-block;
      margin-right: 20px;
      height: 100%;
      width: 40%;
      overflow: hidden;
      border-radius: 8px;
      min-height: 300px;

      @include responsive-down(tablet-extra-large) {
        width: 60%;
      }

      @include responsive-down(tablet-small) {
        width: 80%;
      }

      &__content {
        min-height: 270px;
      }

      p {
        &:nth-child(2) {
          @include responsive-down(tablet-small) {
            font-size: 26px;
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:first-child {
        margin-left: calc(((100vw - 1440px) / 2) + 90px);

        @include responsive-up(desktop-large) {
          margin-left: calc(((100vw - 1640px) / 2) + 90px);
        }

        @include responsive-down(desktop-extra-small) {
          margin-left: 50px;
        }

        @include responsive-down(tablet) {
          margin-left: 30px;
        }

        @include responsive-down(mobile-large) {
          margin-left: 20px;
        }
      }
    }
  }

  &__controls {
    padding: 30px 0;
    display: flex;
    justify-content: flex-end;
    column-gap: 15px;

    @include responsive-down(mobile-large) {
      justify-content: flex-start;
    }

    .btn-slide {
      background-color: $primary;
      padding: 7px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent !important;
      cursor: pointer;

      i {
        font-size: 22px;
        color: $white;
      }

      &--prev {
        transform: rotate(180deg);
      }
    }
  }
}
