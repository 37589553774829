.sectionabout {
  background-color: $background;
  padding: 150px 0 50px;
  position: relative;
  z-index: 2;
  height: 180vh;

  @include responsive-down(height-tablet-extra-small) {
    height: 200vh;
  }

  @include responsive-down(height-mobile-large) {
    height: 230vh;
  }

  @include responsive-down(mobile-large) {
    height: 150vh;
  }

  @include responsive-down(tablet) {
    padding: 180px 0 50px;
    height: 100%;
  }

  &__title {
    font-size: 68px;
    text-align: center;
    color: $green-corporate;
    display: flex;
    flex-direction: column;
    line-height: 1.1;
    padding: 0 50px;
    font-weight: 400;

    @include responsive-down(tablet) {
      font-size: 58px;
      max-width: 900px;
    }

    @include responsive-down(tablet-extra-small) {
      font-size: 48px;
      padding: 0 30px;
    }

    @include responsive-down(mobile-large) {
      font-size: 38px;
      line-height: 1;
      padding: 0 20px;
      max-width: 360px;
    }

    i {
      font-size: 40px;
      color: $primary;
    }

    &-top {
      @include responsive-down(mobile-large) {
        margin: 0 -13px 0;
      }

      i {
        margin: 0 0 0 -10px;

        @include responsive-down(mobile-large) {
          font-size: 30px;
          margin: 0 0 0 -10px;
          padding: 0 10px;
        }
      }
    }

    &-bottom {
      i {
        margin: 0 5px 0 0;

        @include responsive-down(mobile-large) {
          font-size: 25px;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn {
      margin: 50px 0 0;
      z-index: 0;

      @include responsive-down(mobile-large) {
        margin: 60px 0 0;
      }
    }
  }

  &__video {
    width: calc(100% - 200px);
    margin: 120px auto 50px;
    border-radius: 8px;
    overflow: hidden;
    height: 700px;

    @include responsive-down(desktop-small) {
      width: calc(100% - 100px);
      height: 600px;
    }

    @include responsive-down(tablet) {
      width: calc(100% - 50px);
      height: 500px;
    }

    @include responsive-down(mobile-large) {
      width: 100%;
      height: 480px;
      border-radius: 0;
      margin: 100px auto 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
