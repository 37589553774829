.sliderimg-facilities {
  background-color: $background;
  padding: 20px 0 80px;

  &__list {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    position: relative;
    height: 650px;

    @include responsive-down(tablet-extra-large) {
      height: 550px;
    }

    @include responsive-down(tablet) {
      height: 450px;
    }

    @include responsive-down(mobile-large) {
      height: 350px;
    }

    @include responsive-down(mobile) {
      height: 200px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    display: inline-block;
    margin-right: 20px;
    height: 100%;
    width: 80%;
    overflow: hidden;
    border-radius: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:first-child {
      margin-left: calc(((100vw - 1440px) / 2) + 90px);

      @include responsive-up(desktop-large) {
        margin-left: calc(((100vw - 1640px) / 2) + 90px);
      }

      @include responsive-down(desktop) {
        margin-left: 50px;
      }

      @include responsive-down(tablet) {
        margin-left: 30px;
      }

      @include responsive-down(mobile-large) {
        margin-left: 20px;
      }
    }
  }

  &__controls {
    padding: 30px 0;

    .container {
      display: flex;
      justify-content: flex-end;
      column-gap: 15px;

      @include responsive-down(mobile-large) {
        justify-content: flex-start;
      }
    }

    .btn-slide {
      background-color: $primary;
      padding: 7px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent !important;
      cursor: pointer;

      i {
        font-size: 22px;
        color: $white;
      }

      &--prev {
        transform: rotate(180deg);
      }
    }
  }
}
