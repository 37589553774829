/* common::variables
--------------------------------------------------------------------------------------------------------------------- */

// common::variables::breakpoints
$desktop-extra-large: 2560px;
$desktop-large: 1920px;
$desktop: 1600px;
$desktop-small: 1440px;
$desktop-extra-small: 1366px;
$tablet-extra-large: 1280px;
$tablet-large: 1080px;
$tablet: 991px;
$tablet-small: 860px;
$tablet-extra-small: 768px;
$mobile-extra-large: 720px;
$mobile-large: 640px;
$mobile: 540px;
$mobile-small: 480px;
$mobile-extra-small: 375px;

// common::variables::typography
$font-main: 'DM Sans', sans-serif;
$icomoon: 'icomoon', sans-serif;
$letter-spacing: 0.03rem;
$letter-spacing-2x: 0.06rem;
$letter-spacing-big: 0.1rem;

// common::variables::colors::base
$primary-light: #7ee2bf;
$primary: #01c680;
$green-primary-hover: #019461;
$green-corporate: #12543e;
$green-dark: #0a2e22;
$circle: #176e51;
$jardin: #a9b7a7;
$beige: #ebe3d5;
$grey: #595650;
$secondary-hover: #3679ad;
$white: #fff;
$background-grey: #fafafa;
$light-grey: #ebebeb;
$mid-grey: #777;
$dark-grey: #4a4a4a;
$light-black: #222;
$mid-black: #111;
$black: #1c1c1c;
$blockquote:#9d9d9d;
$background: #faf7f2;

// common::variables::colors::text
$title-color: $mid-black;
$text-color: #627f73;
$post-color: $dark-grey;
$text-color-negative: #9e9990;

// common::variables::colors::alerts
$success: #8ac148;
$success-hover: #599014;
$info: #00a9f4;
$info-hover: #007ac1;
$warning: #ff9800;
$warning-hover: #c66900;
$danger: #e53635;
$danger-hover: #ab000e;
