
.facilities {
  position: relative;
  padding: 278px 0;

  @include responsive-down(tablet) {
    padding: 80px 0 90px;
    height: 100%;
    overflow: hidden;
  }

  &__content {
    max-width: 50%;
    position: relative;
    z-index: 1;

    @include responsive-up(desktop) {
      max-width: 40%;
    }

    @include responsive-down(tablet) {
      max-width: 80%;
      position: relative;
    }

    @include responsive-down(tablet-extra-small) {
      max-width: 90%;
    }

    @include responsive-down(mobile-large) {
      max-width: 100%;
      width: 100%;
    }

    .description {
      h1,
      h2,
      h3,
      h4,
      h5 {
        padding: 0 0 23px;
        line-height: 0.95;
        font-size: 68px;
        width: 100%;
        color: $green-corporate;
        max-width: 500px;
        font-weight: 400;

        @include responsive-down(tablet-extra-small) {
          padding: 0 0 30px;
          font-size: 48px;
          width: 100%;
        }

        @include responsive-down(mobile-large) {
          padding: 0 0 20px;
        }
      }

      p {
        width: calc(100% - 70px);
        margin: 0;
        font-size: 18px;
        color: $text-color;

        @include responsive-down(tablet-extra-large) {
          width: 100%;
        }
      }
    }

    .btn {
      margin: 50px 0;

      @include responsive-down(mobile-large) {
        margin: 30px 0;
      }
    }
  }

  &__video {
    position: absolute;
    top: 40px;
    right: 120px;
    width: 50%;
    z-index: -1;
    border-radius: 8px;
    overflow: hidden;
    display: grid;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include responsive-down(desktop) {
      width: 850px;
    }

    @include responsive-down(tablet-extra-large) {
      width: 750px;
      top: 110px;
      right: 60px;
    }

    @include responsive-down(tablet-large) {
      width: 660px;
    }

    @include responsive-down(tablet) {
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      opacity: 1;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 250px;
      margin: 40px 0 0;
    }

    &--mobile {
      display: none;
      position: absolute;
      width: calc(100% - 150px);
      height: 100%;
      align-items: center;
      justify-content: center;
      left: 0;
      bottom: 0;

      @include responsive-down(tablet) {
        display: flex;
      }
    }
  }

  &--about {
    @include responsive-down(tablet) {
      padding: 50px 0 40px;
    }

    .facilities__content {
      max-width: 41%;

      @include responsive-up(desktop) {
        max-width: 40%;
      }

      @include responsive-down(tablet) {
        max-width: 80%;
      }

      @include responsive-down(tablet-extra-small) {
        max-width: 90%;
      }

      @include responsive-down(mobile-large) {
        max-width: 100%;
        width: 100%;

        .btn {
          margin: 45px 0 20px;
        }
      }

      .description {
        h1,
        h2,
        h3,
        h4,
        h5 {
          max-width: 480px;
        }
      }
    }
  }

  &--sustainable {
    .facilities__video {
      img {
        @include responsive-down(tablet) {
          object-fit: contain;
        }
      }
    }
  }
}
