/* layouts::partials::footer
--------------------------------------------------------------------------------------------------------------------- */
.footer {
  background-color: $green-corporate;
  z-index: 99;
  position: relative;

  &__content {
    width: 100%;
    padding: 80px 0 0;

    .container {
      display: flex;
      column-gap: 50px;

      @include responsive-up(desktop-large) {
        max-width: 1920px;
      }

      @include responsive-down(mobile-extra-large) {
        flex-direction: column;
      }
    }
  }

  &__logo {
    display: inline-block;

    svg {
      width: 240px;
    }
  }

  &__info {
    margin: 0 auto 0 0;

    @include responsive-down(tablet-large) {
      width: 40%;
    }

    @include responsive-down(tablet) {
      width: 50%;
    }

    @include responsive-down(mobile-extra-large) {
      width: 100%;
    }

    .location {
      color: $jardin;
      line-height: 1.3;
      font-size: 16px;
      margin: 30px 0;

      @include responsive-down(tablet-small) {
        max-width: 280px;
      }

      @include responsive-down(mobile-extra-large) {
        margin: 45px 0;
      }
    }

    .linksinfo {
      display: flex;
      flex-direction: column;

      .linkfooter-line {
        text-decoration: underline;
        margin: 0 0 12px;

        &:last-child {
          margin: 70px 0 0;

          @include responsive-down(mobile-extra-large) {
            margin: 40px 0 0;
          }
        }
      }
    }
  }

  &__col-content {
    display: flex;
    width: 45%;
    column-gap: 70px;

    @include responsive-up(desktop-large) {
      width: auto;
      column-gap: 120px;
      margin: 0 150px 0 0;
    }

    @include responsive-down(tablet-large) {
      width: 55%;
    }

    @include responsive-down(tablet) {
      width: 50%;
      display: flex;
      flex-direction: column;
    }

    @include responsive-down(mobile-extra-large) {
      width: 100%;
      padding: 80px 0 0;
    }
  }

  .btn-slide-up {
    position: absolute;
    right: 40px;
    background-color: transparent;
    padding: 7px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent !important;

    @include responsive-down(tablet-extra-large) {
      top: 33%;
    }

    @include responsive-down(tablet-small) {
      bottom: 190px;
      top: auto;
      right: 20px;
    }

    &::before {
      content: "";
      background-color: $primary;
      position: absolute;
      width: 40px;
      height: 40px;
      border-radius: 50px;
      transform: scale(1);

      @include transition(all 0.3s ease-in-out);
    }

    i {
      font-size: 22px;
      color: $white;
      z-index: 2;
    }

    &:hover {
      @include responsive-up(tablet-extra-large) {
        &::before {
          transform: scale(1.2);

          @include transition(all 0.3s ease-in-out);
        }
      }
    }
  }

  &__column {
    display: flex;
    flex-direction: column;

    @include responsive-down(tablet) {
      margin: 0 0 40px;
    }

    .title-col {
      font-size: 24px;
      color: $text-color;
      margin: 0 0 30px;
    }

    .linkfooter-line {
      margin: 0 0 20px;
      max-width: 280px;
    }
  }

  &__legend {
    padding: 10px 0;
    margin: 20px 0 0;
    border-top: 0.5px solid $text-color;

    @include responsive-down(mobile-extra-large) {
      padding: 30px 0;
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      @include responsive-up(desktop-large) {
        max-width: 1920px;
      }

      @include responsive-down(tablet) {
        flex-direction: column-reverse;
      }

      @include responsive-down(mobile-extra-large) {
        align-items: center;
      }
    }

    .textlegend {
      font-size: 11px;
      color: $text-color;

      @include responsive-down(mobile-extra-large) {
        text-align: center;
        color: $beige;
        margin: 40px 0 0;
        line-height: 1.2;
      }
    }

    .linknumericco {
      font-size: 11px;
      color: $primary !important;

      @include transition(all 0.4s cubic-bezier(0.1, 0, 0.3, 1));

      @include responsive-up(tablet-extra-large) {
        &:hover {
          color: $beige !important;

          @include transition(all 0.4s cubic-bezier(0.1, 0, 0.3, 1));
        }
      }
    }
  }

  &__legal {
    .linklegal {
      color: $beige;
      font-size: 11px;
      margin: 0 0 0 20px;

      @include transition(all 0.4s cubic-bezier(0.1, 0, 0.3, 1));

      @include responsive-down(tablet) {
        margin: 0 20px 0 0;
      }

      @include responsive-down(mobile-extra-large) {
        margin: 0 5px;
      }

      &:hover {
        @include responsive-up(tablet-extra-large) {
          color: $primary;

          @include transition(all 0.4s cubic-bezier(0.1, 0, 0.3, 1));
        }
      }
    }
  }
}

.contactbottom {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 80px 0;
  overflow: hidden;

  @include responsive-down(mobile-large) {
    flex-direction: column;
    align-items: center;
    padding: 50px 0 57px;
  }

  &::after {
    content: '';
    width: 180vw;
    height: 180vw;
    aspect-ratio: 1/1;
    background-color: $circle;
    opacity: 0.4;
    position: absolute;
    bottom: 0;
    border-radius: 50%;
    z-index: 0;
  }

  .markee {
    position: relative;
    z-index: 1;
  }

  .btn {
    position: absolute;
    z-index: 2;
    padding: 20px 45px;

    @include responsive-down(mobile-large) {
      padding: 14px 45px;
      position: relative;
      margin: 25px 0 0;
    }

    span {
      font-size: 48px;

      @include responsive-down (tablet-extra-large) {
        transform: translate(11px, 0);
      }

      @include responsive-down(mobile-large) {
        font-size: 30px;
      }
    }

    &::before {
      width: 16px;
      height: 16px;
      left: 32px;

      @include responsive-down (tablet-extra-large) {
        left: 30px;
      }

      @include responsive-down (mobile-large) {
        width: 12px;
        height: 12px;
        left: 34px;
      }
    }

    &:hover {
      @include responsive-up(tablet-extra-large) {
        span {
          transform: translate(14px, 0);
        }
      }
    }
  }
}
