.section-textimg {
  padding: 150px 0;

  .container {
    display: flex;
    align-items: center;
    gap: 50px;
  }

  .img {
    width: 50%;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    align-items: center;
    max-height: 500px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    width: 50%;
  }
}
