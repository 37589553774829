/* style
--------------------------------------------------------------------------------------------------------------------- */

@import "_variables/variables";
@import "_variables/mixins";
@import "_variables/_responsive/up";
@import "_variables/_responsive/down";
@import "_variables/_responsive/only";
@import "_common/extends";
@import "_layouts/home";
@import "_layouts/_partials/footer";
@import "_layouts/_partials/cookies";
@import "_layouts/_blocks/sectionprocess";
@import "_layouts/_blocks/sectionclients";
@import "_layouts/_blocks/facilities";
@import "_layouts/_blocks/certifications";
@import "_layouts/_blocks/sectionabout";
@import "_layouts/_blocks/ourhistory";
@import "_layouts/_blocks/teamcompany";
@import "_layouts/_blocks/about-facilities";
@import "_layouts/_blocks/facilities-process";
@import "_layouts/_blocks/sliderimg-facilities";
@import "_layouts/_blocks/sectionsteps";
@import "_layouts/_blocks/sectionprotect";
@import "_layouts/_blocks/process-list";
@import "_layouts/_blocks/section-textimg";
