.certifications {
  background-color: $background;
  padding: 100px 0 150px;

  @include responsive-down(tablet) {
    padding: 90px 0 100px;
  }

  .container {
    display: flex;
    justify-content: space-between;

    @include responsive-down(tablet-extra-large) {
      column-gap: 50px;
    }

    @include responsive-down(tablet) {
      flex-direction: column;
      row-gap: 100px;
    }

    @include responsive-down(mobile-large) {
      row-gap: 30px;
    }
  }

  &__intro {
    max-width: 500px;

    @include responsive-down(tablet-extra-large) {
      width: 40%;
    }

    @include responsive-down(tablet) {
      width: 100%;
    }

    .title {
      font-size: 38px;
      font-weight: 400;
      margin: 0 0 25px;
      line-height: 1.1;
      color: $green-corporate;

      @include responsive-down(tablet) {
        font-size: 30px;
        margin: 0 0 35px;
      }
    }

    .subtitle {
      font-size: 16px;
      color: $text-color;
      margin: 0;
      line-height: 1.4;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 600px);
    grid-row-gap: 90px;

    @include responsive-down(tablet-extra-large) {
      width: 60%;
    }

    @include responsive-down(tablet) {
      width: 100%;
      grid-row-gap: 50px;
    }

    .itemcertification {
      width: calc(100% / 2);

      @include responsive-down(mobile-large) {
        width: 100%;
      }

      &__text {
        max-width: 160px;
      }

      .link-arrow {
        span {
          @include responsive-down(tablet-extra-large) {
            font-size: 13px;
          }
        }

        .arrow {
          @include responsive-down(tablet-extra-large) {
            width: 12px;
          }
        }
      }

      &__img {
        width: 70px;
      }
    }
  }

  &--home {
    @include responsive-down(tablet-small) {
      padding: 40px 0 100px;
    }
  }
}
