.about-facilities {
  padding: 70px 0 160px;
  background-color: $background;

  @include responsive-down(mobile-large) {
    padding: 70px 0 110px;
  }

  &__intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    margin: 0 auto;

    @include responsive-down(mobile-large) {
      align-items: flex-start;
      max-width: none;
      margin: 0;
    }

    .btn {
      margin: 45px 0 0;

      @include responsive-down(mobile-large) {
        margin: 35px 0 0;
      }
    }

    h2 {
      font-size: 38px;
      margin: 0 0 15px;
      line-height: 1;
      color: $green-corporate;
      font-weight: 400;
      text-align: center;

      @include responsive-down(tablet) {
        font-size: 30px;
      }

      @include responsive-down(mobile-large) {
        text-align: left;
        margin: 0 0 23px;
      }
    }

    p {
      margin: 0;
      font-size: 16px;
      text-align: center;
      color: $text-color;
      line-height: 1.3;

      @include responsive-down(mobile-large) {
        text-align: left;
      }
    }
  }

  &__title {
    font-size: 38px;
    margin: 0 0 15px;
    line-height: 1;
    color: $green-corporate;
    font-weight: 400;
    text-align: center;

    @include responsive-down(tablet) {
      font-size: 30px;
    }

    @include responsive-down(mobile-large) {
      text-align: left;
      margin: 0 0 23px;
    }
  }

  &__subtitle {
    margin: 0;
    font-size: 16px;
    text-align: center;
    color: $text-color;
    line-height: 1.3;

    @include responsive-down(mobile-large) {
      text-align: left;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    padding: 65px 0 0;

    @include responsive-down(mobile-large) {
      display: flex;
      flex-direction: column;
    }
  }

  &__img {
    height: 460px;
    border-radius: 8px;
    overflow: hidden;

    @include responsive-down(mobile-large) {
      height: 210px;
    }

    img,
    .map {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--1,
    &:first-child {
      grid-area: 1 / 1 / 2 / 7;
    }

    &--2,
    &:nth-child(2) {
      grid-area: 1 / 7 / 2 / 10;
    }
  }

  &--reverse {
    padding: 90px 0 160px;
    background-color: $background;

    @include responsive-down(mobile-large) {
      padding: 80px 0 110px;
    }

    .about-facilities__img {
      &--1,
      &:first-child {
        grid-area: 1 / 4 / 2 / 10;
      }

      &--2,
      &:nth-child(2) {
        grid-area: 1 / 1 / 2 / 4;
      }
    }

    .introtext-center {
      max-width: 600px;

      &__subtitle {
        width: 60%;
      }
    }
  }
}
