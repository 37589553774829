.facilities-process {
  padding: 50px 0 180px;

  @include responsive-down(tablet-small) {
    padding: 100px 0 90px;
  }

  @include responsive-down(mobile-large) {
    padding: 70px 0 90px;
  }

  .introtext {
    max-width: 720px;

    &__title {
      margin: 0 0 15px;

      @include responsive-down(mobile-large) {
        margin: 0 0 25px;
      }
    }
  }

  &__download {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 20px;
  }

  &__slider {
    padding: 54px 0 0;
  }

  &__list {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    position: relative;
    height: 290px;

    @include responsive-down(mobile-small) {
      height: 200px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .itemprocess {
      display: inline-block;
      margin-right: 20px;
      min-height: auto;
      height: 100%;
      width: calc(100% / 3 - 10px);

      @include responsive-down(desktop) {
        width: calc(100% / 2 - 110px);
      }

      @include responsive-down(tablet) {
        width: 500px;
      }

      @include responsive-down(mobile-large) {
        width: 85%;
        margin-right: 10px;
      }

      &__title {
        margin: 0 0 10px;
      }

      &__content {
        padding: 20px 20px 27px;
        width: 100%;
      }

      &:first-child {
        margin-left: calc(((100vw - 1440px) / 2) + 90px);

        @include responsive-up(desktop-large) {
          margin-left: calc(((100vw - 1640px) / 2) + 90px);
        }

        @include responsive-down(desktop-extra-small) {
          margin-left: 50px;
        }

        @include responsive-down(tablet) {
          margin-left: 30px;
        }

        @include responsive-down(mobile-large) {
          margin-left: 20px;
        }
      }
    }
  }
}
