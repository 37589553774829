.sectionclients {
  padding: 107px 0 0;
  display: none;

  @include responsive-down(tablet) {
    padding: 70px 0 0;
  }

  &__title {
    font-size: 1.5rem;
    color: $green-corporate;
    margin: 0 0 50px;
    column-gap: 40px;

    @include responsive-down(mobile-large) {
      font-size: 20px;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;

    @include responsive-down(tablet-extra-large) {
      flex-wrap: wrap;
      justify-content: flex-start;
      column-gap: 30px;
      row-gap: 30px;
    }

    .img,
    img {
      width: 150px;
      height: auto;
      object-fit: contain;

      @include responsive-down(tablet-extra-large) {
        width: calc(100% / 4 - 30px);
      }

      @include responsive-down(mobile-large) {
        width: calc(100% / 2 - 30px);
      }
    }
  }
}
