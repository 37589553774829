.sectionprotect {
  padding: 150px 0;
  background-color: $background;
  z-index: 9;
  position: relative;

  @include responsive-down(tablet) {
    padding: 80px 0;
  }

  .introtext {
    margin: 0 0 30px;
    display: flex;

    @include responsive-down(tablet) {
      flex-direction: column;
    }

    &__col {
      width: calc(100% - 200px);

      @include responsive-down(tablet) {
        width: 100%;
      }
    }

    &__title {
      margin: 0 0 30px;
    }

    &__subtitle {
      padding: 0 0 30px 50px;

      @include responsive-down(tablet) {
        padding: 0 0 30px 0;
      }
    }
  }

  &__img {
    width: 100%;
    height: 500px;
    border-radius: 8px;
    overflow: hidden;
    margin: 100px 0 0;

    @include responsive-down(tablet) {
      height: 350px;
      margin: 40px 0 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
