/* common::extends
--------------------------------------------------------------------------------------------------------------------- */
.btn {
  color: #fff;
  background-color: $primary !important;
  display: inline-flex;
  align-items: center;
  border-radius: 100px;
  padding: 7px 25px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: none !important;
  isolation: isolate;

  span,
  &__text {
    font-size: 1rem;
    z-index: 2;
    color: white;
    position: relative;
    transform: translate(0, 0);
    transition: all 0.4s cubic-bezier(0.1, 0, 0.3, 1);
  }

  &::before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50px;
    background-color: $white;
    transform: scale(0);
    position: absolute;
    left: 15px;
    transition: all 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    z-index: 2;
    transform-origin: left center;
  }

  &::after {
    content: '';
    position: absolute;
    background: $green-primary-hover;
    width: 120%;
    padding-bottom: 120%;
    left: -10%;
    border-radius: 50%;
    transform: translate3d(-70%, 0, 0) scale3d(0.2, 0.2, 0.2);
    transition: all 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    z-index: 1;
    transform-origin: left center;
  }

  &:hover {
    @include responsive-up(tablet-extra-large) {
      &::after {
        transform: translate3d(0%, 0, 0) scale3d(1, 1, 1);
        transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
        left: -10%;
      }

      &::before {
        transform: scale(1);
        transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
      }

      .btn__text {
        transform: translate(6px, 0);
        transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
      }
    }
  }

  @include responsive-down(tablet-extra-large) {
    &::before {
      transform: scale(1);
      transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    }

    .btn__text {
      transform: translate(6px, 0);
      transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    }
  }

  &--transparent {
    backdrop-filter: saturate(170%) blur(18px);
    -webkit-backdrop-filter: saturate(10%) blur(20px);
    background: rgba(255, 255, 255, 0.2) !important;
  }
}

.link-arrow {
  display: inline-flex;
  align-items: center;

  span,
  &__text {
    font-size: 0.813rem;
    color: $primary;
  }

  .arrow {
    width: 15px;
    margin: 0 0 0 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;

    svg {
      width: 15px;
      height: auto;
    }

    &::before {
      content: '';
      width: 20px;
      height: 20px;
      border-radius: 50px;
      background-color: $primary-light;
      opacity: 0.3;
      transform: scale(0);
      transform-origin: center center;
      position: absolute;
      transition: all 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    }
  }

  &:hover {
    @include responsive-up(tablet-extra-large) {
      .arrow {
        &::before {
          transform: scale(1);
          transition: all 0.4s cubic-bezier(0.1, 0, 0.3, 1);
        }
      }
    }
  }
}

.link-line {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;

  &__text {
    font-size: 1rem;
    color: $white;
    position: relative;
    transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &::before {
    content: "";
    height: 1px;
    background-color: $primary;
    position: absolute;
    bottom: -1px;
    transform-origin: center;
    width: 100%;
    transform: scaleX(0);
    transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
  }

  .svg {
    margin: 0 0 0 5px;
    width: 15px;
    fill: $white;
    transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:hover {
    @include responsive-up(tablet-extra-large) {
      .link-line__text {
        color: $primary;
        transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
      }

      &::before {
        transform: scaleX(1);
        transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
      }

      .svg {
        fill: $primary;
        transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
      }
    }
  }

  &--black {
    .link-line__text {
      color: $black;

      &::before {
        background-color: $black;
      }
    }
  }

  &--green {
    .link-line__text {
      color: $primary;

      &::before {
        background-color: $primary;
      }
    }
  }
}

.introtext {
  &__title,
  &__subtitle,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $green-corporate;
    margin: 0;
  }

  &__title,
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0 0 10px;
    font-weight: 400;
    font-size: 38px;
    line-height: 1.2;

    @include responsive-down(tablet) {
      font-size: 30px;
    }
  }

  &__subtitle,
  p {
    line-height: 1.2;
    color: $text-color;
    font-size: 16px;
  }
}

.introtext-center {
  &__title {
    font-size: 38px;
    margin: 0 0 15px;
    line-height: 1;
    color: $green-corporate;
    font-weight: 400;
    text-align: center;
  }

  &__subtitle {
    margin: 0;
    font-size: 16px;
    text-align: center;
    color: $text-color;
    line-height: 1.3;
  }

  &__btn {
    margin: 45px 0 0;
  }
}

.itemprocess {
  border-radius: 8px;
  height: 490px;
  position: relative;
  overflow: hidden;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &::before {
      content: '';
      width: 100%;
      height: 130%;
      position: absolute;
      top: 0;
      left: 0;
      mix-blend-mode: multiply;
      transition: all 0.3s ease;
      background: transparent linear-gradient(181deg, #0a2e2200 0%, #0a2e22d1 73%, #0a2e22 100%) 0% 0% no-repeat padding-box;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    max-width: 400px;
  }

  &__title {
    font-size: 1.5rem;
    color: $beige;
    line-height: 1.2;
    margin: 0;
  }

  &__subtitle {
    p {
      font-size: 13px;
      color: $text-color-negative;
      font-weight: 300;

      strong {
        color: $beige;
      }
    }
  }

  &__description {
    margin: 0 auto;
    opacity: 0;
    max-height: 0;
    transition: all 0.3s ease;
  }

  &:hover {
    @include responsive-up(tablet-extra-large) {
      .itemprocess__description {
        transition: all 0.3s ease;
        opacity: 1;
        max-height: 150px;
      }

      .itemprocess__img {
        &::before {
          height: 100%;
          transition: all 0.3s ease;
        }
      }
    }
  }

  @include responsive-down(tablet-extra-large) {
    .itemprocess__description {
      transition: all 0.3s ease;
      opacity: 1;
      max-height: 100%;
    }

    .itemprocess__img {
      &::before {
        height: 100%;
        transition: all 0.3s ease;
      }
    }
  }

  &--slide {
    .itemprocess__description {
      max-height: none !important;
      margin: 0;
      opacity: 1;
      visibility: visible;
    }
  }

  &--office {
    background-color: $background;

    .itemprocess__title {
      color: $green-corporate !important;
    }

    .location,
    .linksinfo a {
      color: $text-color !important;
    }
  }
}

.itemcertification {
  display: flex;
  align-items: center;

  &__img {
    margin: 0 10px 0 0;
    width: 60px;
    height: 100%;
    object-fit: contain;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__text {
    font-size: 16px;
    color: $text-color;
    margin: 0 0 10px;
    line-height: 1.1;
  }
}

.itemblog {
  display: flex;
  flex-direction: column;

  &__img {
    border-radius: 8px;
    height: 260px;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include transition(all 0.3s ease-in-out);
    }

    // &::before {
    //   content: '';
    //   width: 100%;
    //   height: 200%;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   background: transparent linear-gradient(181deg, #0a2e2200 0%, #0a2e22d1 73%, #0a2e22 100%) 0% 0% no-repeat padding-box;

    //   @include transition(all 0.3s ease-in-out);
    // }
  }

  &__content {
    margin: 25px 0 0;
    display: flex;
    align-items: flex-start;
    column-gap: 20px;

    .category {
      font-size: 11px;
      color: $text-color;
      white-space: nowrap;

      &--featured {
        font-size: 12px;
        background-color: $grey;
        border-radius: 50px;
        padding: 5px 10px;
        color: $white;
        text-align: center;
      }
    }

    .title {
      font-size: 20px;
      color: $green-corporate;
      margin: -6px 0 0;
      line-height: 1.6;
    }
  }

  &--small {
    flex-direction: row;

    .itemblog__content {
      flex-direction: column;
    }
  }

  &:hover {
    @include responsive-up(tablet-extra-large) {
      .itemblog__img {
        img {
          transform: scale(1.2);

          @include transition(all 0.3s ease-in-out);
        }

        // &::before {
        //   height: 100%;

        //   @include transition(all 0.3s ease-in-out);
        // }
      }
    }
  }
}

.markee {
  display: flex;
  align-items: center;
  padding: 24px 0;
  overflow: hidden;
  white-space: nowrap;

  @include responsive-down(tablet-small) {
    padding: 16px 0;
  }

  &__content {
    animation: moveBigText 175s infinite linear;
    display: flex;
    align-items: center;

    @keyframes moveBigText {
      0% {
        transform: translate(0, 0);
      }

      100% {
        transform: translate(-100%, 0);
      }
    }

    i {
      margin: 0 20px 0 25px;
      font-size: 52px;
      color: $primary;

      @include responsive-down(tablet-small) {
        font-size: 42px;
      }
    }
  }

  &__title {
    font-size: 53px;
    font-family: $font-main;
    text-transform: uppercase;
    margin: 0;
    line-height: 1;
    color: $jardin;

    @include responsive-down(tablet-small) {
      font-size: 40px;
    }

    &--beige {
      color: $beige;
      font-style: italic;
      margin: 0 0 0 10px;
    }
  }

  &--home {
    background-color: $green-corporate;
  }
}

.linkfooter-line {
  font-size: 16px;
  color: $beige !important;

  @include transition(all 0.4s cubic-bezier(0.1, 0, 0.3, 1));

  &:hover {
    @include responsive-up(tablet-extra-large) {
      color: $primary !important;

      @include transition(all 0.4s cubic-bezier(0.1, 0, 0.3, 1));
    }
  }
}

.itemiconvalue {
  display: flex;
  column-gap: 20px;

  i {
    font-size: 50px;
    color: $primary;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin: 10px 0 0;

    .title {
      font-size: 24px;
      color: $green-corporate;
      margin: 0 0 15px;
      line-height: 1.2;
    }

    .description {
      font-size: 1rem;
      color: $text-color;
      margin: 0;
      line-height: 1.4;
    }
  }
}

.card-user {
  display: inline-flex;
  column-gap: 20px;
  align-items: center;

  &__img {
    width: 75px;
    height: 75px;
    border-radius: 50px;
    overflow: hidden;
    display: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    .name {
      font-size: 24px;
      color: $beige;
      margin: 0 0 8px;
      line-height: 1;

      @include responsive-down(tablet) {
        font-size: 20px;
      }
    }

    .job {
      color: $jardin;
      font-size: 16px;
      margin: 0;
      line-height: 1.3;
    }
  }
}

.itemstep {
  padding: 30px;
  background: $background;
  border-radius: 8px;

  p {
    font-size: 1rem;
    color: $text-color;
    line-height: 1.2;
    margin: 22px 0;
    white-space: pre-wrap;

    &:first-child {
      color: $green-corporate;
    }

    &:nth-child(2) {
      font-size: 30px;
      font-weight: 500;
      color: $green-corporate;
    }

    a {
      text-decoration: underline;
      color: $green-corporate !important;

      &:hover {
        @include responsive-up(tablet-extra-large) {
          color: $primary !important;
        }
      }
    }
  }

  ul {
    padding: 0;

    li {
      position: relative;
      list-style: none;
      padding: 0 0 10px 30px;
      color: $green-corporate;
      font-size: 14px;

      &::before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 1px;
        background: $primary;
        position: absolute;
        left: 0;
        top: 9px;
      }
    }
  }

  &__content {
    height: 100%;
  }
}

/* FORMS
--------------------------------------------------------------------------------------------------------------------- */
.forms {
  &-group {
    height: 60px;
    position: relative;
    font-size: 14px;
    color: $grey;
    z-index: 0;
    border-bottom: 1px solid $light-grey;
    background-color: rgba(235, 227, 213, 0.5);
    border-radius: 8px;

    .input {
      width: 100%;
      height: 100%;
      border: 0;
      padding: 20px;
      outline: none;
      margin: 0 10px;
      font-size: 14px;
      opacity: 0.8;
      z-index: 2;
      position: relative;
      background-color: transparent;

      &--focused {
        top: -2px;
        font-size: 10px;
      }
    }

    .label {
      display: inline;
      padding: 8px 4px 8px;
      box-sizing: content-box;
      position: absolute;
      top: 6px;
      left: 15px;
      transition: top 0.2s, font-size 0.2s;
      color: $jardin;
      z-index: 2;
      font-size: 16px;
    }

    &--error {
      color: $danger;
      border-bottom: 1px solid $warning;

      .label {
        color: $danger;
      }
    }

    &--select {
      position: relative;
      display: flex;
      align-items: center;

      &::before {
        content: "\e900";
        font-family: $icomoon;
        font-size: 18px;
        font-weight: 600;
        position: absolute;
        right: 20px;
      }

      select {
        width: 100%;
        height: 60px;
        padding: 0 17px;
        background: transparent;
        font-size: 20px;
        font-family: $font-main;
        color: rgba(89, 86, 80, 0.5);
        appearance: none;

        option {
          font-size: 16px;
          font-family: $font-main;
          color: rgba(89, 86, 80, 0.5);

          &::-webkit-input-placeholder {
            color: rgba(89, 86, 80, 0.5);
          }
        }
      }
    }

    p {
      margin: 0;
      line-height: 1;
    }

    &--linkedin {
      position: relative;
      display: flex;
      align-items: center;
      height: 70px;

      input {
        padding: 20px 20px 20px 60px !important;
      }

      &::before {
        content: "\e90c";
        font-family: $icomoon;
        font-size: 25px;
        position: absolute;
        left: 20px;
      }
    }

    &--file {
      position: relative;
      display: flex;
      align-items: center;
      height: 70px !important;

      input {
        padding: 20px 20px 20px 56px !important;
        font-size: 12px !important;
        color: $black !important;
        font-weight: 500;
      }

      label {
        position: absolute;
        top: 15px;
        left: 56px;
        font-size: 14px;
        color: rgba(89, 86, 80, 0.7);
      }

      &::before {
        content: "\e908";
        font-family: $icomoon;
        font-size: 22px;
        position: absolute;
        left: 20px;
      }
    }
  }
}

/* CHECKBOX
--------------------------------------------------------------------------------------------------------------------- */
.form-checkbox {
  display: block;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    opacity: 0;
    cursor: pointer;
    display: none;

    & + span.wpcf7-list-item-label::before,
    & + label::before {
      content: '';
      background-color: rgba(235, 227, 213, 0.5);
      border-radius: 100%;
      border: 1px solid $grey;
      display: inline-block;
      width: 20px;
      height: 20px;
      position: relative;
      top: -0.2em;
      margin-right: 10px;
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 0.25s ease;
    }

    &:checked {
      & + span.wpcf7-list-item-label::before,
      & + label::before {
        content: "";
        background-color: $primary;
        box-shadow: inset 0 0 0 3px #ebf9f6;
      }
    }
  }

  span {
    font-family: $font-main;
    color: $text-color;
    font-size: 13px;
  }

  label {
    position: relative;
    font-size: 12px !important;
    font-weight: 400 !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $text-color;
    font-family: $font-main;

    @include responsive-down(mobile-extra-small) {
      flex-wrap: wrap;
    }

    a {
      font-weight: 200;
      margin-left: 4px;
      font-size: 13px;
      color: $text-color !important;
      font-family: $font-main;

      &:hover {
        @include responsive-up(tablet) {
          color: $black !important;
        }
      }
    }
  }

  .legend {
    font-size: 10px;
    display: block;
    font-weight: 200;
    line-height: 1.3;
    margin: 10px 0 0;
  }
}

input::file-selector-button {
  font-weight: 500;
  color: $black;
  padding: 0.5em 0 0.3em 0;
  margin: 0 0.5em 0 0;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $primary;
  outline: none;
}

/* MODAL-------------------------------------------------------------------------------------------------------------- */
.modalvideo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(35, 35, 35, 0.8);
  z-index: 1002;
  display: none;
  justify-content: center;
  align-items: center;

  &--active {
    display: flex;
    padding: 30px;

    @include responsive-down(mobile-large) {
      padding: 20px;
    }
  }

  &__content {
    height: auto;
    position: relative;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1000px;

    @include responsive-down(desktop-small) {
      max-width: 800px;
    }

    @include responsive-down(tablet-extra-large) {
      max-width: 600px;
    }
  }

  &__close {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: -45px;
    z-index: 100;
    width: 30px;
    height: 30px;

    .icon-close {
      position: absolute;
      top: 15px;
      right: 3px;
      width: 25px;
      height: 2px;
      background-color: $white;
      transition: transform 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: rotate(-45deg);

      &::before {
        content: "";
        left: 0;
        right: 0;
        top: 0;
        position: absolute;
        height: 2px;
        background-color: $white;
        transition: transform 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: rotate(90deg);
      }
    }
  }

  &__video {
    text-align: center;
    overflow: hidden;
    position: relative;

    iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    video {
      width: 100%;
      height: 100%;
    }
  }
}

.js-slider-drag {
  cursor: grab;
}

.slider__controls {
  padding: 30px 0;

  .container {
    display: flex;
    justify-content: flex-end;
    column-gap: 15px;

    @include responsive-down(mobile-large) {
      justify-content: flex-start;
    }
  }

  .btn-slide {
    background-color: $primary;
    padding: 7px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent !important;
    cursor: pointer;

    i {
      font-size: 22px;
      color: $white;
    }

    &--prev {
      transform: rotate(180deg);
    }
  }
}

@keyframes feadein {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}
